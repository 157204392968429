.dropdownMenu {
    all: unset;
    padding: unset;
    margin: unset;
  }

  .services-menu {
    width: fit-content;
    position: absolute;
    text-align: left;
    border-radius: 0px 8px 8px 8px;
    background-color: var(--accent-color-dark);
  }
  .services-menu li {
    text-align: left;
    padding-right: 16px;
    line-height: 35px;
    padding: auto;
  }

  .services-menu li a {
    color: var(--primary-color-white);
    display: unset;

  }
  
  .services-menu.clicked {
    display: none;
  }
 
  .services-menu li:hover {
    text-decoration: underline solid var( --primary-color-white);
  }


  .services-submenu {
    width: fit-content;
    position: absolute;
    right:-220px;
    top: 90px;
    text-align: left;
    border-radius: 0px 8px 8px 8px;
    background-color: var(--accent-color-dark);
    padding: auto;
  }
  .services-submenu li {
    text-align: left;
    padding-right: 16px;
    line-height: 30px;
  }

  .services-submenu li a {
    color: var( --primary-color-white);
    display: unset;
  }
  
  .services-submenu.clicked {
    display: none;
  }
 
  .services-submenu li:hover {
    text-decoration: underline solid var( --primary-color-white);
  }

  .prod-engg-submenu {
    width: fit-content;
    position: absolute;
    right:-220px;
    top: 120px;
    text-align: left;
    border-radius: 0px 8px 8px 8px;
    background-color: var(--accent-color-dark);
    padding: auto;
  }
  .prod-engg-submenu li {
    text-align: left;
    padding-right: 16px;
    line-height: 30px;
  }

  .prod-engg-submenu li a {
    color: var( --primary-color-white);
    display: unset;

  }
  
  .prod-engg-submenu.clicked {
    display: none;
  }
 
  .prod-engg-submenu li:hover {
    text-decoration: underline solid var( --primary-color-white);
  }
 
  @media screen and (max-width: 891px) {
    .services-menu {
   margin: auto;
     position: relative;
     border-radius: 0px ;
      background-color: var(--primary-color-white); 
  
    }
    .services-menu li {
line-height: 2.5rem;
text-align: center;
    }
       
  .services-menu li a{
    color: var(--accent-color-dark)!important;

  }
  .services-menu li{
    line-height: 2.5rem;
    text-align: center;
    text-decoration: none !important;
  }
  .services-menu li a:hover{
    color: var(--primary-color-white)!important;
  }
  
}


 @media screen and (max-width: 891px) {
    .prod-engg-submenu {
      display: none;
    }
  }
  