

.signinbutton{
  display: inline-block;
    text-align: center;
    padding: 8px;
    background: #173966;
    color: #fff;
    font-size: 10px;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    width: 150px;
    margin: 10px auto 0;
    border-radius: 10px;
}

.chat-img{
    
    border-radius: 50%;
    height: 25px;
    margin-top: -20px;
    border: 2px solid black;
}
.chat-p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    overflow-wrap: break-word;
}
.msg {
    display: flex;
    width:80%;
    padding: 10px 5px 0 10px;
    margin: 20px;
    border-radius: 3000px;
    box-shadow: 0 0 10px rgb(164, 164, 164);
    align-items: center;
  }
  .msgs {
 
    margin: 110px 0;
    display: flex;
    flex-direction: column;
  }
.sent {
    background-color: #395dff;
    color: white;
    border-top-right-radius: 1000px;
    flex-direction: row-reverse;
    padding: 20px 20px 0 10px;
    text-align: end;
    float: right;
  }
  
  .received {
    border: 1px solid lightgray;
    background-color: #FAFAFA;
    border-top-left-radius: 1000px;
    float: left;
  }