.button {
    border: 2px solid var(--button-color);
    position: fixed;
    right: 0px;
    bottom: 15px;
    background-color: var(--button-color);
    color: white;
    font-size: 22px;
    padding: 10px 20px 10px;
    border-radius: 30px 0 0 30px;
    z-index: 100;
  }
  .button-icon {
    display: none;
    border: 2px solid var(--button-color);
    position: fixed;
    right: 0px;
    bottom: 10px;
    background-color: var(--button-color);
    color: white;
    font-size: 22px;
    padding: 10px 20px 10px;
    border-radius: 30px 0 0 30px;
    z-index: 100;
  }
  .button:hover {
    color: white;
  }
  @media screen and (max-width: 489px) {
    .button-icon {
      display: block;
    }
    .button {
      display: none;
    }
    .button-icon:hover {
      color: white;
    }
  }
  