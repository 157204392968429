.QuoteButton{
    position: fixed;
    right: 0px;
     bottom: 15px;
         text-align: center;
         padding: 12px;
         background: var(--button-color);
         color: #fff;
         font-size: 10px;
         border: 0;
         cursor: pointer;
         font-size: 14px;
         width: 150px;
         border-radius: 10px;
    z-index: 100;
  
 }
 .close-icon{
  position: absolute;
 right: 40px;
 }
 h5 {
    color: #173966;
    font-weight: 800;
    margin-bottom: 6px;
    text-align: center;
    padding-top: 8px;
  }
 .privacy-text{
    color: #173966;
  }

  .request-form {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .form-input {
    height: 2rem;
    width: 80%;
    border: 0;
    padding: 15px;
    outline: none;
     font-size: 16px;
 
  }
  .form-select {
    height: fit-content;
    width: 80%;
    border: 0;
      outline: none;
    font-size: 16px;
    padding: 5px;
    margin-bottom: 10px;
  }

 

  .browse-file{
  
    border: 0;
    line-height: 1rem;
    padding: 0;
    margin-bottom: 0;
    padding-bottom: 0; 
  }

  .form-label{
    line-height: 6px;
    padding: 4px;
   padding: 4px;
   
  }

  .form-textarea {
    border: 0;
    padding: 20px;
    outline: none;

    font-size: 16px;
    width: 80%;
  }
  .QuoteForm-button {
    display: inline-block;
    text-align: center;
    padding: 8px;
    background: #173966;
    color: #fff;
    font-size: 10px;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    width: 150px;
    margin: 10px auto 0;
    border-radius: 10px;
  }