.HeadingOverImg img{
    width:100vw;
    height:85vh;
    overflow: hidden;
}
.HeadingText {
    height: 85vh;
    width: 96vw;
    position: absolute;
    top:6.5rem;
    background: rgba(23, 57, 102, 0.6);
    opacity: 1; 
  }
  .HeadingText h1{
    color: var(--primary-color-white);
    display: inline-block;
    font-size: 70px;
    margin: auto;
    font-weight: 800;
    padding-top: 15rem;
    margin-left: 4%;
  }

  .HeadingText h4{
    color: var(--primary-color-white);
    display: inline-block;
    font-size: 36px;
    margin: auto;
    font-weight: 800;
    margin-left: 7%;
    margin-top: 1rem;
  }
  @media screen and (max-width:450px){
    /* .HeadingOverImg img{
      width:100vw;
      height:55vh;
      overflow: hidden;
  }
  .HeadingText {
      height: 55vh;
      width: 96vw;
      position: absolute;
      top:6.5rem;
      background: rgba(23, 57, 102, 0.6);
      opacity: 1; 
    } */
    .HeadingText h1{
      font-size: 60px;
    }
    .HeadingText h4{
      font-size: 28px;
    }
  }
  @media screen and (max-width:380px){
    .HeadingText h1{
      font-size: 45px;
    }
    .HeadingText h4{
      font-size: 22px;
    }
  }
  @media screen and (max-width:290px){
    .HeadingText h1{
   font-size: 40px;
    }
    .HeadingText h4{
      font-size:20px;
  }
  }