.career-container {
    background-color: #f2f2f2;
   
  }
  .career-container h3 {
    text-align: center;
    color: #173966;
    font-size: 24px;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin-bottom: 10px;
  }
  .career {
    margin: 10px 5px 10px 15px;
  }
  .careerContents {
    width: 60%;
  }
  
  .careerContents p {
    font-size: 14px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: auto;
    padding: 1rem;
  }
  .careerimg {
    width: 40%;
  }
  .careerimg img {
    width: 90%;
    display: inline-block;
  }
  @media screen and (max-width: 850px) {
    .career {
      flex-direction: column;
    }
    .careerContents {
      width: 100%;
      margin: auto;
    }
    .careerimg {
      width: 100%;
    }
    .careerContents p {
    
      margin-left: 0;
    }
  }
  