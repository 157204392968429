.error {
  background-color: #b3c8d7;
  height: 50vh;
  overflow: hidden !important;
}
.error h1 {
  color: var(--primary-color);
  margin-top: 8rem;
  font-weight: 600;
}

.error button {
  padding: 10px;
  border-radius: 2px;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 50%;
}
.error button a {
  color: var(--accent-color-dark);
}
