


.ShowHideNav{
  margin-block: 20px;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);;
  margin-top: 10px;
  margin-bottom: 80px;
}
.title {
    all: unset;
    display: flex;
    gap: 20px;
    overflow: hidden;
    border: 0;
    padding: 12px;
    justify-content: flex-end;
    width: 100%;
    margin: auto;
  }
  .title button {
    all: unset;
    color: var(--accent-color-dark)!important;
    border: 0;
    font-size: 20px;
    background-color: unset;
    font-weight: 500;  
  
  }

  .title button:hover {
    all: unset;
    color: var(--accent-color-dark)!important;
    font-size: 20px;
    background-color: unset;
    font-weight: 500;
  }
  
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    all: unset;
    font-size: 20px;
    text-underline-offset: 8px;
    background-color: unset !important;
    color: var(  --accent-color-dark) !important;
    border: 0;
    border: none;
    font-weight: 500;
    border-bottom: 3px solid;
    border-image: linear-gradient(102.57deg,#173966,#b600b3 70%,#bc4e4e 0);
    border-image-slice: 1;
    background: transparent;
  }
  .nav-link.active {
    border: 0;
    border: none;
    background-color: unset !important;
    color: var(--primary-color) !important;
    all: unset;
  }

  ul li {
    list-style: none;
  }
  .tab-cont {
    width: 90%;
    justify-content: space-around;
    margin: auto;
  
  }
  .tabImg {
    width: 55%;
    justify-content: center;
  }
  .tabDesc {
    width: 35%;
    text-align: center;
    justify-content: flex-start;
  }
  .tabImg img {
    width: 90%;
    justify-content: center;
    margin: auto;
  }
  @media screen and (max-width: 951px) {
    .tab-cont {
      flex-direction: column;
    }
    .tabImg {
      width: 70%;
    }
    .tabImg img {
      width: 100%;
      padding: 10px;
    }
    .tabDesc {
      width: 90%;
    }
    .title {
      padding: 12px;
    }
   
  
  }
  @media screen and (max-width: 320px) {
    .tabImg {
      display: none;
    }
    .tabDesc {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 571px) {
    .title {
      gap: 10px;
      padding: 8px;
    }
    .title button {
      font-size: 16px;
    }
    .title button:hover {
      font-size: 16px;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
      font-size: 16px;
    }
  }
  