.service-text-container p{
padding: 1rem;
font-size: 1.2rem;
}

.service {
    margin: 4rem 6rem;
    color: black;
  }
  .servicecard {
    display: flex;
    justify-content: space-between;
  }
  .servicecard .active{
    filter: blur(8px);
  }
  .s-card {
    width: 33%;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;
    padding: 0.5rem 0.8rem;
    position: relative;
  }
  .s-image {
    width: 75%;
    overflow: hidden;
  }
  .s-image img {
    width: 100%;
    height: 100%;
  }
  .background {
    height: 96%;
    width: 70%;
    position: absolute;
    top: 2%;
    left: 3%;
  }
  h3 {

    display: none;
  }
  .background:hover {
    background: rgba(23, 57, 102, 0.9);
  }
  
  .background:hover h3 {
    display: block;
    color: var(--primary-color-white);
    position: absolute;
    /* transform: scale(2); */
    bottom: 50%;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    /* transition: 0.2s ease-in-out; */

  }
  
  @media screen and (max-width: 850px) {
    .service {
      margin: 4rem 2rem;
    }
    .s-image {
      margin: auto;
    }
    .servicecard {
      flex-direction: column;
    }
    .s-card {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  @media  screen and (max-width:580px){
   
    .service-text-container p{
     font-size: 1rem;
     padding: 1.5rem;
      
      }
      
   
  }
  @media  screen and (max-width:380px){
    .service-text-container p{
      font-size: .9rem;  
      text-align: center;
       
       }
   
  }
  

  