h3 {
    color: var(--accent-color-dark);
  }
  .aboutDesc {
    padding: 8px;
    margin: 20px;
    margin-left: 55px;
    margin-top: 10px;
  }
  .About-Container {
    width: 99%;
  }

  .cont{
    max-width: 70vw;
    margin: auto;
  }