.Main-Cont {
  flex-direction: column;
  width: 85vw;
  height: fit-content;
  margin: auto;
  position: relative;
  gap: 10px;
}
.Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;
  margin: auto;
}
.items img {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 10px;
}

@media screen and (min-width: 1441px) {
  .Gallery-container {
    height: fit-content;
    padding: 20px;
    background-color: var(--primary-color);
    border: 1px solid var(--accent-color-dark);
    position: unset;
    width: 70vw;
  }
  .Main-Cont {
    height: fit-content;
    position: unset;
  }
  .Container {
    width: 80vw;
  }
}
@media screen and (max-width: 991px) {
  .Gallery-container {
    height: fit-content;
    padding: 20px;
    background-color: var(--primary-color);
    border: 1px solid var(--accent-color-dark);
    position: unset;
    width: 60vw;
  }
  .Main-Cont {
    height: fit-content;
    position: unset;
  }

}

@media screen and (max-width: 769px) {
  .Container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
@media screen and (max-width: 426px) {
  .Container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }
}
