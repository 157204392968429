.CardOverImgCard-Cont{
 justify-content: space-around;

}
.CardOverImgCard-cards {
    height: 260px;
    padding: 30px;
    margin-bottom: 30px;
    width: 31%;
   border-radius: 2px;
    z-index: 10;
    background: rgba(44, 59, 80, 0.91);
  }
  .CardOverImgCard h6{
text-align: center;
font-weight: 600;
color: var( --primary-color-white);
  }
  .CardOverImgCard h2{
    color: var( --primary-color-white);
      }
  .CardOverImgCard p{
    text-align: center;
    color: var( --primary-color-white);
  }
  @media screen and (max-width: 991px) {
  .CardOverImgCard-Cont{
    flex-direction: column;
  }
    .CardOverImgCard-cards {
      width: 70%;
      height: 230px;
    }
  }
  @media screen and (max-width: 768px) {
  
      .CardOverImgCard-cards {
        width: 80%;
      }
    }
  @media screen and (max-width:380px){
    .CardOverImgCard p{
  font-size: 0.8rem;
  padding: 10px;
    }
    .CardOverImgCard h2{
    text-align: center;
      font-size: 1.2rem;
        }
  }