.form-container {
    padding: 10px;
    color: black;
    background-color: #f2f2f2;
    margin-bottom: 20px;
  }
  form{
    padding: 0px;
  }
  .form-container h3 {
    color: #173966;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;
  }
  .form-container form {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .form-container input {
    height: 1rem;
    border: 0;
    padding: 20px;
    outline: none;
    margin: 10px 0;
    font-size: 16px;
  }
  .form-container textarea {
    border: 0;
    padding: 20px;
    outline: none;
    margin: 10px 0;
    font-size: 16px;
  }
  .form-container button {
    display: inline-block;
    text-align: center;
    padding: 8px;
    background: #173966;
    color: #fff;
    font-size: 10px;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    width: 150px;
    margin: 10px auto 0;
    border-radius: 10px;
  }

  
  @media screen and (max-width: 850px) {
    .form-container {
      margin: 1rem 2rem;  
    }
    .form-container form {
      padding-top: 2rem;
      width: 100%;
    }
  }
  