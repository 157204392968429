.section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlap-img {
  width: 100%;
  height: 600px;
}
.overlap-img img {
  width: 100%;
  height: 100%;
}
.overlap-desc {
  width: 60%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  margin-left: -200px;
  background-color: var(--primary-color-white);
}
.overlap-contents h4{
  text-align: left;
 }
@media screen and (max-width: 1024px) {
  .overlap-desc {
    width: 80%;
    margin-left: -200px;
  }
}
@media screen and (max-width: 800px) {
  .overlap {
    flex-direction: column;
    width: 100%;
    margin: 0px 20px;
  }
  .overlap-img img {
    width: 100%;
    height: 400px;
  }
  .overlap-desc {
    width: 80%;
    margin: 0;
    margin-top: -360px;
  }
}
@media screen and (max-width: 591px) {
  .overlap-desc {
    width: 90%;
  }
}
@media screen and (max-width: 426px) {
  .overlap-desc {
    width: 95%;
    border-radius: 4px;
  }
}
@media screen and (max-width: 321px) {
  .overlap-desc {
    width: 100%;
    border-radius: 0px;
    padding: 10px;
    margin-top: 0px;
  }
  .overlap-img {
    display: none;
  }
}
