
.InsightImg {
  width: 50%;
}
.InsightImg img{
    border-left: 6px solid;
    border-image: linear-gradient(#173966, #b600b3 70%, #bc4e4e 0);
    border-image-slice: 1;
    background: transparent;
    padding-left: 30px;
    text-underline-offset: 8px;
  }
.Insights {
  width: 100vw;
  margin: auto;
  overflow: hidden;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 4rem;
  gap: 2rem;
}

.Insights-img Img {
  width: 100%;
  margin: auto;
  padding: 1rem;
}
.Insights-desc {
  width: 60%;
}
.Insights-desc h1{
    padding: 50px;
    border-left: 6px solid;
    border-image: linear-gradient(#173966, #b600b3 70%, #bc4e4e 0);
    border-image-slice: 1;
    background: transparent;
    padding-left: 30px;
    text-underline-offset: 8px;
}
.Insights-desc p {
  text-align: justify;
  font-size: 20px;
 
}
.Insights-desc h1 {
  text-align: left;
  margin-left: 6rem;
}
.Insights-desc li{
  list-style-type: disc;
}


@media screen and (max-width: 1024px){
    .Insights{
        flex-direction: column-reverse;
    }
    .InsightImg img{
        border-left: none;
        border-bottom: 6px solid;
        border-image: linear-gradient(#173966, #b600b3 70%, #bc4e4e 0);
        border-image-slice: 1;
        background: transparent;
        text-underline-offset: 8px;
        padding-bottom: 30px;
        margin: auto;
      }
      .Insights-desc h1{
        border-left: none;
        padding: 50px;
        text-align: justify;
        border-bottom: 6px solid;
        border-image: linear-gradient(#173966, #b600b3 70%, #bc4e4e 0);
        border-image-slice: 1;
        background: transparent;
        padding-left: 30px;
        text-underline-offset: 8px;
    }
      .InsightImg{
        width: 80%;
        margin: auto;
      }
      .InsightImg img{
        width: 100%;
        padding: 40px;
      }
      .Insights-desc {
        width: 95%;
        margin: auto;
        padding: 1rem;
      }
      .Insights-desc h1{
        font-size: 24px;
        margin-left: 0;
      }
} 
