.tiles-container {
    height: fit-content;
    margin-block: 10px;
    padding: 20px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 80%;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }
  .tiles-container:hover {
    box-shadow: 0 0 20px 0 rgba(23, 57, 102, 0.5);
  }
  .tiles {
    text-align: center;
  }
  .tiles a {
    color: var(--accent-color-dark);
  }
  

  @media screen and (max-width: 547px) {
    .tiles-container {
      width: 90%;
    }
  }
  @media screen and (max-width: 425px) {
    .tiles-container {
      width: 100%;
    }
  }
  