.Gallery-cont{
    height: fit-content;
    margin: auto;
    margin-top: 60px;
    overflow: hidden;

}




