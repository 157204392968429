.CardOverImg{
  width: 100%;
}
.CardOverImg-Img{
    height: 400px;
}
.CardOverImg-Img img{
    width: 100%;
}
.CardOverImg-Img img:hover{
 transform: scale(1.2);
 transition: 4s;

}
.CardOverImg-cards{
    width: 100%;
    margin: 0;
    margin-top:-5%;
}
@media screen and (max-width: 991px) {
   
    .CardOverImg-cards{
        margin-top:-15%
    }
  }
  @media screen and (max-width: 768px) {
   
    .CardOverImg-cards{
        margin-top:-30%
    }
  }
  @media screen and (max-width: 644px) {
   
    .CardOverImg-cards{
        margin-top:-40%
    }
  }
  @media screen and (max-width: 544px) {
   
    .CardOverImg-cards{
        margin-top:-50%
    }
  }
  @media screen and (max-width: 496px) {
   
    .CardOverImg-cards{
        margin-top:-60%
    }
  }
  @media screen and (max-width: 426px) {
    .CardOverImg-Img{
        display: none;
    }
    .CardOverImg-cards{
        margin-top:0
    }
  }
 
