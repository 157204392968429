.contact{
    flex-direction: row;
   gap: 10px;
   margin: auto;
 
}
.contactform{
    width: 85%;
}
.contactdetail{
    width: 33%;
}

  
@media screen and (max-width:1024px) {
    .contact{
        flex-direction: column;
       gap: 5px;
       margin: auto;
      
    } 
    .contactform{
        width: 100%;
    }
    .contactdetail{
        width: 100%;
    }
}
@media screen and (max-width:320px) {
    .contact{
        flex-direction: column;
       gap: 5px;
       margin: 10px;
      
    } 
    
}