.TextBesideImage {
  width: 100vw;
  overflow: hidden;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 4rem;
  gap: 2rem;
}
.TextBesideImage-img {
  width: 50%;
}
.TextBesideImage-img Img {
  width: 100%;
  margin: auto;
  padding: 1rem;
}
.TextBesideImage-desc {
  width: 60%;
}
.TextBesideImage-desc p {
  text-align: justify;
  font-size: 20px;
}
.TextBesideImage-desc h1 {
  text-align: left;
  margin-left: 6rem;
}

@media screen and (max-width: 1024px) {
  .TextBesideImage {
    flex-direction: column-reverse;
  }
  .TextBesideImage-img {
    width: 100vw;
    margin: auto;
    
  }
  .TextBesideImage-desc {
    width: 100%;
    margin-block: 40px;
    font-size: 18px;
  }
  .TextBesideImage-img Img {
    width: 90vw;
   margin: auto;
    padding: 1rem;
  }
}
